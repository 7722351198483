// NOTIFIER: ERROR TYPE STYLES

$notifier-error-background-color: #d9534f !default;
$notifier-error-font-color: #fff !default;
$notifier-error-icon-color: #fff !default;

.notifier__notification--error {
  background-color: $notifier-error-background-color;
  color: $notifier-error-font-color;

  .notifier__notification-button-icon {
    // 16x16 fixed size
    fill: $notifier-error-icon-color;
  }
}
