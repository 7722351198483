//
// Form editors.scss
//


.ck.ck-toolbar {
    background-color: $gray-200 !important;
    border: 1px solid $gray-300 !important;
  }
  
  .ck.ck-editor__main>.ck-editor__editable {
    border-color: $gray-300 !important;
    box-shadow: none !important;
  }
  
  .ck.ck-dropdown__panel,
  .ck.ck-list {
    background: $dropdown-bg !important;
    border: 1px solid $dropdown-border-color !important;
  }
  
  .ck-editor__editable {
    min-height: 245px !important;
  }
  
  .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
    flex-wrap: wrap !important;
  }
  
  .ck.ck-editor__main>.ck-editor__editable {
    background-color: $card-bg !important;
  }
  
  .ck.ck-reset_all,
  .ck.ck-reset_all * {
    color: $gray-600 !important
  }
  
  .ck.ck-toolbar .ck.ck-toolbar__separator {
    background: transparent !important;
  }
  
  .ck.ck-button.ck-on,
  a.ck.ck-button.ck-on,
  .ck.ck-button:not(.ck-disabled):hover,
  a.ck.ck-button:not(.ck-disabled):hover {
    background: $gray-200 !important;
  }
  


// Tinymce

.tox-tinymce {
    border: 1px solid $input-border-color !important;
}

.tox {
    .tox-collection__item {
        color: $dropdown-link-color !important;
    }

    .tox-menu.tox-collection.tox-collection--list.tox-selected-menu,
    .tox-collection--toolbar.tox-collection--toolbar-lg.tox-selected-menu,
    .tox-swatches-menu.tox-selected-menu {
        box-shadow: $box-shadow-sm !important;
        animation-name: DropDownSlide !important;
        animation-duration: 0.3s !important;
        animation-fill-mode: both !important;
        margin: 0 !important;
        position: absolute !important;
        z-index: 1000 !important;
        padding: 0.5rem 0 !important;
        background-color: $dropdown-bg !important;
        border: $dropdown-border-width solid $dropdown-border-color !important;
        border-radius: 0.25rem !important;
    }

    .tox-collection--list .tox-collection__group {
        border-color: $border-color !important;
    }

    .tox-collection--list {
        .tox-collection__item--active {
            color: $dropdown-link-hover-color !important;
            background-color: $dropdown-link-hover-bg !important;
        }
    }

    .tox-collection__group-heading {
        color: $dropdown-link-hover-color !important;
        background-color: $dropdown-link-hover-bg !important;
    }

    .tox-statusbar {
        border-top: 1px solid $light !important;
    }

    .tox-menubar,
    .tox-edit-area__iframe,
    .tox-statusbar {
        background-color: $card-bg !important;
        background: none !important;
    }

    .tox-mbtn {
        color: $gray-700 !important;

        &:hover:not(:disabled):not(.tox-mbtn--active) {
            background-color: $light !important;
        }
    }

    .tox-tbtn {
        &:hover {
            background-color: $light !important;
        }
    }

    .tox-toolbar,
    .tox-toolbar__overflow,
    .tox-toolbar__primary {
        background: $light !important;
    }

    .tox-toolbar__primary {
        border-top-color: $light !important;
    }

    .tox-tbtn {
        color: $gray-700 !important;

        svg {
            fill: $gray-700 !important;
        }
    }

    .tox-edit-area__iframe {
        background-color: $card-bg !important;
    }

    .tox-statusbar a,
    .tox-statusbar__path-item,
    .tox-statusbar__wordcount {
        color: $gray-700 !important;
    }

    &:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
        border-right: 1px solid darken($light, 5%) !important;
    }

    .tox-collection--toolbar {
        .tox-collection__item--active {
            background-color: $gray-300 !important;
        }
    }
}

// x editable

.editable-input {
    .form-control {
        display: inline-block;
    }
}

.editable-buttons {
    margin-left: 7px;

    .editable-cancel {
        margin-left: 7px;
    }
}